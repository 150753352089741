<template>
  <div class="editData">
    <el-dialog
      :title="type==1 ?'新增':'修改'"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form label-width="auto" :rules="rules" ref="form" :model="form">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-form-item label="封面图">
                <el-upload
                  v-loading="loading"
                  class="avatar-uploader"
                  :action="this.$server.api + 'command/ossUpload?filename=file'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  style="width:180px"
                >
                  <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" /><i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                  ></i>
                </el-upload>
              </el-form-item></div
          ></el-col>
          <el-col :span="10" class="userif" v-if="this.type==2"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="ID" >
                <el-input v-model="form.id" style="width: 180px" disabled></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="商品名称">
                <el-input v-model="form.name"  style="width: 180px" ></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-form-item label="分类">
                <el-select
                  style="width: 180px"
                  v-model="form.type"
                  clearable
                  placeholder="请选择分类"
                >
                  <!-- <el-option label="请选择分类" :value="0"></el-option> -->
                  <el-option label="沈阳" value="1"></el-option>
                  <el-option label="北京" value="2"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-form-item label="状态" prop="sta">
                <el-select
                  style="width: 180px"
                  v-model="form.sta"
                  clearable
                  placeholder="请选择状态"
                >
                  <el-option label="启用" :value="1"></el-option>
                  <el-option label="禁用" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="售价" prop="price">
                <el-input v-model="form.price"  style="width: 180px" ></el-input
              ></el-form-item></div
          ></el-col>
           <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="年限" prop="year">
                <el-input v-model="form.year"  style="width: 180px"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"
                ><div class="grid-content bg-purple-light">
                <el-form-item label="期数" prop="relet">
                    <el-input v-model="form.relet"  style="width: 180px" ></el-input
                ></el-form-item></div
            ></el-col>
            <el-col :span="12"
                ><div class="grid-content bg-purple-light">
                <el-form-item label="每期价格" prop="relet_price">
                    <el-input v-model="form.relet_price"  style="width: 180px"></el-input
                ></el-form-item></div
            ></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="安装费" prop="install">
                <el-input v-model="form.install"  style="width: 180px" ></el-input
              ></el-form-item></div
          ></el-col>
           <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="通讯费" prop="report">
                <el-input v-model="form.report"  style="width: 180px"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
              <el-form-item prop="detail" label="商品详情">
                    <file-fu
                    v-model="form.detail"
                    :isClear="isClear"
                    @change="change"
                  ></file-fu>
              </el-form-item>
          </el-col>
        </el-row>
        
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goodscreate, boxgoodsedit } from "@/request/api";
import fileFu from "./fileFu.vue";

export default {
  name: "AddDialog",
  components: {fileFu},
  data() {
    return {
      isClear: false,
      loading:false,
      type:'',
        form: {
            id: '',
            name: '',
            imgUrl: '',
            price: '',
            year: '',
            relet: '',
            relet_price: '',
            install: '',
            report: '',
            detail: '',
            type: '',
            sta: '',
            
            },
       dialogVisible:false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        imgUrl: [
          {
            required: true,
            message: "请选择封面图",
            trigger: "blur",
          },
        ],
        price: [{ required: true, message: "请输入售价", trigger: "blur" },],
        year: [{required: true, message: "请输入年限",  trigger: "blur"},],
        relet: [{required: true, message: "请输入期数",  trigger: "blur"},],
        relet_price: [{required: true, message: "请输入每期价格",  trigger: "blur"},],
        install: [{required: true, message: "请输入安装费",  trigger: "blur"},],
        report: [{required: true, message: "请输入通讯费",  trigger: "blur"},],
        detail: [{required: true, message: "请输入商品富文本",  trigger: "blur"},],
        type: [{required: true, message: "请选择商品分类",  trigger: "blur"},],
        sta: [{required: true, message: "请选择商品状态",  trigger: "blur"},],
        
        
      },
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
    //获取修改的信息
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      this.loading=true
      return isLt2M;
    },
    handleAvatarSuccess(res, file) {
      let imgurl = res.data;
      this.form.imgUrl = imgurl;
      // this.ruleForm.img = imgurl;
      const data = file.raw;
      this.putObject(data);
    },
    async putObject(data) {
      let OSS = require("ali-oss");
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-cn-beijing",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: "LTAI5tS4HMwn4NcLE9Hf2iUa",
        accessKeySecret: "wGxicSjxwjRbJzza84PplXTuySdA1l",
        // 从STS服务获取的安全令牌（SecurityToken）。
        // stsToken: "yourSecurityToken",
        // 填写Bucket名称。
        bucket: "yujianweilai02-tp5",
      });
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = await client.put(
          "/image/" + new Date().getTime() + ".jpg",
          data
          // { headers }
        );
        this.form.imgUrl = result.url;
        this.loading=false
      } catch (e) {
        console.log(e);
      }
    },
      show(type, row) {
      this.type=type
      // console.log(type,row,'5665665')
      if (this.type == 2) {
        this.dialogVisible = true;
        this.form.id=row.id
        this.form.name = row.name
        this.form.imgUrl=row.img
        this.form.price=row.price
        this.form.relet=row.relet
        this.form.relet_price=row.relet_price
        this.form.sta=row.sta
        this.form.type = row.type
        this.form.year=row.year
        this.form.detail = row.detail
        this.form.install=row.install
        this.form.report=row.report
        }
      else {
       this.dialogVisible = true;
      }
    },
    close() {
      this.dialogVisible = false;
      this.form.id=''
      this.form.name = ''
      this.form.imgUrl=''
      this.form.price=''
      this.form.relet=''
      this.form.relet_price=''
      this.form.sta=''
      this.form.type = ''
      this.form.year=''
      this.form.detail = ''
      this.form.install=''
      this.form.report=''
      this.form.imgUrl=''
    },
    change(val) {
      this.form.detail = val;
    },
    submitForm(form) {
       this.$refs[form].validate((valid) => {
         if (valid != false) {
          if (this.type == 1) {
            let params = {
            token: sessionStorage.getItem("token"),
            name: this.form.name,
            img: this.form.imgUrl,
            price: this.form.price,
            year: this.form.year,
            relet: this.form.relet,
            relet_price: this.form.relet_price,
            install: this.form.install,
            report: this.form.report,
            detail: this.form.detail,
            type: this.form.type,
            sta:this.form.sta
            }
            goodscreate(params).then((res) => {
              if (res.data.code == 200) {
                this.close()
                this.$message.success("新增成功");
                this.$parent.getUserList()
              }
              else {
                this.close()
                this.$message.error(res.data.msg);
                this.$parent.getUserList()
                }
            })
          this.close()           
          }
          else {
            let params = {
              id:this.form.id,
              token: sessionStorage.getItem("token"),
              name: this.form.name,
              img: this.form.imgUrl,
              price: this.form.price,
              year: this.form.year,
              relet: this.form.relet,
              relet_price: this.form.relet_price,
              install: this.form.install,
              report: this.form.report,
              detail: this.form.detail,
              type: this.form.type,
              sta:this.form.sta
            }
            boxgoodsedit(params).then((res) => {
              if (res.data.code == 200) {
                 this.close()              
                this.$parent.getUserList()
                this.$message.success("编辑成功");

              }
              else {
                this.close()              
                this.$parent.getUserList()
                this.$message.error(res.data.msg);
                  
                }
            })
          }
          
         }
      })
    },
   
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.xian {
  margin-left: -75px !important;
}
.userif {
  margin-top: 100px;
}
</style>
