<template>
  <div class="user">
   <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="商品名称" >
          <el-input
            style="width: 180px"
            v-model="goods_name"
            clearable
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" >
          <el-select
            v-model="type"
            placeholder="请选择"
            style="width: 180px"
          >
            <el-option label="全部分类" value=""></el-option>
            <el-option label="沈阳" value="1"></el-option>
            <el-option label="北京" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="search()">搜索</el-button>
          <el-button type="primary" @click="add(1,'')">添加</el-button>
        </el-form-item>
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="id" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="name" label="名称" align="center">
      </el-table-column>
      <el-table-column prop="img" label="封面图" align="center">
         <template slot-scope="scope">
          <img :src="scope.row.img" alt="" srcset="" width="60" />
        </template>
      </el-table-column>
      <el-table-column prop="price" label="售价" align="center">
      </el-table-column>
      <el-table-column prop="year" label="年限" align="center">
      </el-table-column>
      <el-table-column prop="relet" label="期数" align="center">
      </el-table-column>
      <el-table-column prop="relet_price" label="每期价格" align="center">
      </el-table-column>
      <el-table-column prop="install" label="安装费" align="center">
      </el-table-column>
      <el-table-column prop="report" label="通讯费" align="center">
      </el-table-column>
      <el-table-column prop="type" label="分类" align="center">
        <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.type == 1">沈阳</el-link>
          <el-link type="danger" v-if="scope.row.type == 4"
            >北京</el-link
          >
        </template>
      </el-table-column>
      <el-table-column prop="sta" label="状态" align="center">
       <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.sta == 1">启用</el-link>
          <el-link type="danger" v-if="scope.row.sta == 2"
            >禁用</el-link
          >
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column>
      <!-- <el-table-column prop="deleted_at" label="发放时间" align="center"> 
      </el-table-column> -->
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="add(2,scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </page-table>
    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData"></edit-data>
  </div>
</template>

<script>
import { goodsindex ,owneredit} from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import editData from './components/editData'
import pageTable from "@/components/pageTable.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData
  },
  data() {
    return {
      userList: [], // 用户列表

      goods_name: "",
      type: "",
      time: "",
      ex_status: "",

      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    // add() {
    //   this.$refs.editData.show();
    // },
    add(type, row) {
      let typeTitle =type 
       let rowData = row;
       // 1:新增，2:编辑
       this.$refs.editData.show(typeTitle, JSON.parse(JSON.stringify(rowData)));
     },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },

    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
        let params = {
        token: this.token,
        page:this.page.currentPage,
        limit: this.page.pageSize,
        name: this.goods_name,
        type:this.type
        }
        goodsindex (params).then((res) => {
        console.log(res)
        this.userList = res.data.data
        this.page.total=res.data.count
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      })
    },
    search() {
      console.log('搜索')
      this.page.currentPage = 1
      this.getUserList()
      },
    
  },
};
</script>

<style lang="scss" scoped>
</style>
